export const PriorityLevel = {
  None: 0,
  Low: 1,
  Medium: 2,
  High: 3,
};
export const TodoStatus = {
  New: 0,
  InProgress: 1,
  Done: 10,
};
