<nz-modal [(nzVisible)]="isVisible" nzCentered [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="handleCancel()">
    <!-- <ng-template #modalTitle>
        <div class="text-center text-primary font-semibold text-[40px]">
            <span>{{'PopUpAddEditTask.listSubTask'|translate}}</span>
        </div>
    </ng-template> -->
    <ng-template #modalContent>
        <div class="text-center text-primary font-semibold text-[40px] mb-6">
            <span>{{'PopUpAddEditUnit.listUnitTask'|translate}}</span>
        </div>
        <div *ngFor="let data of listSubUnits">
            <div class="grid grid-cols-[1fr_1fr] item-center gap-2">
                <div class="flex w-full gap-3">
                    <nz-checkbox-wrapper (nzOnChange)="checkedSuccesTask(data)">
                        <label nz-checkbox [nzDisabled]="data.assignee!==userInfor.sub" [(ngModel)]="data.done"
                            [nzValue]="data.id"></label>
                    </nz-checkbox-wrapper>
                    <span class="cursor-pointer hover:opacity-85"
                        (click)="handleOpenAddTask(data.id)">{{ data.name }}</span>
               </div>
                <div class="flex justify-end gap-2">
                    <div class="flex flex-col gap-x-2 items-end">
                        <span class="text-red-500 justify-self-end">{{data.createdDate | date:'dd/MM/yyyy'}}</span>
                        <div class="justify-self-end col-start-3 col-end-4 w-[80px] h-[25px] rounded-[10px]   text-white flex items-center justify-center"
                            [class.bg-green-500]="data.priority===priorityLevelEnum.Low"
                            [class.bg-[#FFB800]]="data.priority===priorityLevelEnum.Medium"
                            [class.bg-red-500]="data.priority===priorityLevelEnum.High">
                            <span
                                *ngIf="data.priority===priorityLevelEnum.High">{{'PopUpAddEditTask.priorityHigh'|translate}}</span>
                            <span
                                *ngIf="data.priority===priorityLevelEnum.Medium">{{'PopUpAddEditTask.priorityMedium'|translate}}</span>
                            <span *ngIf="data.priority===priorityLevelEnum.Low">{{'PopUpAddEditTask.priorityLow'|
                                translate}}</span>
                        </div>
                    </div>

                </div>


            </div>
        </div>
        <div class="hover:text-[#1890ff] w-fit">
            <span nz-icon nzType="circleIcon:antd" class="icon inline-block" nzTheme="outline"></span>
            <span nz-icon nzType="plus-circle" (click)="handleOpenAddSubUnitToParrent()"
                class="cursor-pointer icon inline-block bg-white rounded-[50%] translate-x-[-50%]"
                nzTheme="outline"></span>
        </div>
    </ng-template>

    <ng-template #modalFooter>
    </ng-template>
</nz-modal>

<app-unit-popup-add-edit-sub *ngIf="visibleAddTask" [idTaskDetail]="idTaskDetail" [idUnitSubList]="idUnit"
    (visibleList)="handleVisibleTaskAdd($event)"
    [isVisible]="visibleAddTask" (unitListChilCreated)="handleViewListUnitChil($event)"></app-unit-popup-add-edit-sub>