<nz-modal [(nzVisible)]="isVisiblePopUpAddMember" nzCentered [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="handleCancel()">
    <ng-template #modalContent>
        <div class="text-center text-primary font-semibold text-[40px]">
            <span>{{'PopUpAddEditUnit.member'|translate}}</span>
        </div>
        <input class="w-full px-3 py-1 h-[44px] rounded-xl border-[#ccc] border-solid border-[2px] outline-none" placeholder="Tìm kiếm"
        [(ngModel)]="searchQuery" (ngModelChange)="search($event)">

        <div *ngFor="let option of filteredListOfOption" class="mt-3">
            <div class="flex justify-between items-center border-b-[1px] pb-2">
                <div class="flex gap-2">
                    <input type="checkbox" (change)="onCheckboxChange(option.value, option.text, $event)"
                    [checked]="isMemberChecked(option.value)"
                    >
                    <span>{{option.text}}</span>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #modalFooter>
        <div class="flex justify-center">
            <button nz-button nzType="primary"
                class="!bg-primary border-none rounded-lg hover:opacity-60 hover:bg-primary text-white hover:text-black"
                (click)="handleOk()" [nzLoading]="isConfirmLoading">{{'Button.addMember'| translate }}</button>
            <button nz-button
                class="!bg-[#8B8B8B] border-none rounded-lg hover:opacity-60 text-white hover:text-black"
                (click)="handleCancel()" [nzLoading]="isConfirmLoading">{{'Button.cancel'| translate }}</button>
        </div>
    </ng-template>
</nz-modal>